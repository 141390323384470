exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-forts-index-js": () => import("./../../../src/pages/fortsIndex.js" /* webpackChunkName: "component---src-pages-forts-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-district-js": () => import("./../../../src/templates/district.js" /* webpackChunkName: "component---src-templates-district-js" */),
  "component---src-templates-fort-js": () => import("./../../../src/templates/fort.js" /* webpackChunkName: "component---src-templates-fort-js" */),
  "component---src-templates-forts-js": () => import("./../../../src/templates/forts.js" /* webpackChunkName: "component---src-templates-forts-js" */),
  "component---src-templates-taluk-js": () => import("./../../../src/templates/taluk.js" /* webpackChunkName: "component---src-templates-taluk-js" */)
}

